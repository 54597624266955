import React, {Fragment, useCallback, useEffect} from 'react';
import {
    Author,
    CLASSIFICATION_NAME,
    ClassificationFieldAttribute,
    ClassificationLabel,
    ClassificationUtil,
    LabelData, LayoutUtil,
    Localizer,
    TimeAgo,
    useToggle
} from '@ideascale/commons';
import {useIdeaLabels} from 'hooks/useIdeaLabels';
import {useAppContext} from 'contexts/AppContext';
import {LabelsView} from 'shared/LabelsView';
import {StageAction} from '../StageAction';
import {BaseIdeaStageSummary} from 'models/BaseIdeaStageSummary';
import {VoteParameters} from 'models/VoteParameters';
import {VoteType} from 'models/enums/VoteType';
import './PersistentBanner.scss';

type PersistentBannerProps<T extends BaseIdeaStageSummary> = {
    ideaNumber: number;
    localizer: Localizer;
    ideaTitle: string;
    ideaCreatedAt: string;
    labels: LabelData[];
    submitter: Author;
    stageSummary: T;
    onClickStageAction: () => void;
    onVote: (voteParameters: VoteParameters, voteType: VoteType) => Promise<boolean>;
    ideaViewCount?: number;
    classification?: ClassificationFieldAttribute[]
}

export const PersistentBanner = (props: PersistentBannerProps<BaseIdeaStageSummary>) => {
    const {
        localizer,
        ideaTitle,
        labels,
        submitter,
        ideaCreatedAt,
        stageSummary,
        onClickStageAction,
        onVote,
        ideaNumber,
        ideaViewCount,
        classification
    } = props;
    const {ideaLabelRouteChange} = useIdeaLabels();
    const {communityConfig: {classificationEnabled}} = useAppContext();

    const [visible, toggleVisible] = useToggle(false);

    const toggleBanner = useCallback(() => {
        const scrolled = document.documentElement.scrollTop;
        if (!LayoutUtil.isMobileLayout()) {
            if (scrolled > 300) {
                toggleVisible(true);
            } else if (scrolled <= 300) {
                toggleVisible(false);
            }
        }
    }, [toggleVisible]);

    const isElementObscuredByBanner = useCallback((element: HTMLElement, bannerOffset: number) => {
        const elementInDetails = !!element.closest('.idea-details-content');
        const elementInNav =    !!element.closest('.page-navbar');
        const elementRect = element.getBoundingClientRect();
        return visible && elementRect.top > 0 && elementRect.top < bannerOffset && (elementInDetails || elementInNav);
    }, [visible]);

    const handleFocus = useCallback((event: any) => {
        const focusedElement = event.target;
        const bannerHeight = document.getElementById('banner')?.offsetHeight || 0;
        const scrollBuffer = 10;
        const bannerTop = document.getElementById('banner')?.offsetTop || 0;

        if (isElementObscuredByBanner(focusedElement, bannerHeight + bannerTop + scrollBuffer)) {
            window.scrollBy(0, -bannerHeight - scrollBuffer);
        }
    }, [isElementObscuredByBanner]);

    useEffect(() => {
        window.addEventListener('scroll', toggleBanner);

        return () => {
            window.removeEventListener('scroll', toggleBanner);
        };
    }, [toggleBanner]);

    useEffect(() => {
        document.addEventListener('focusin', handleFocus);

        return () => {
            document.removeEventListener('focusin', handleFocus);
        };
    }, [handleFocus]);

    return (
        <Fragment>
            {
                visible && <section className="idea-details-persistent-banner" id="banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-9 position-relative">
                                <div
                                    className="d-flex persistent-banner-content align-items-center justify-content-between py-2">
                                    <header className="idea-header">
                                        {
                                            <div className="fw-bold font-size-md text-break">
                                                {
                                                    classificationEnabled && classification &&
                                                    <ClassificationLabel
                                                        classification={ClassificationUtil.getAttributeByName(CLASSIFICATION_NAME.EFFECTIVE_CLASSIFICATION, classification)}
                                                        extraClasses="me-2 mb-1"/>
                                                }
                                                {ideaTitle}
                                                <span className="ms-1">
                                                    <LabelsView labels={labels}
                                                                onLabelClick={ideaLabelRouteChange}
                                                                showStackView={false}/>
                                                </span>
                                            </div>
                                        }
                                        <div className="author-info">
                                            <span>{submitter.name}</span>
                                            <TimeAgo localizer={localizer} dateISOString={ideaCreatedAt}
                                                     prefixText={false}/>
                                            <span className="idea-number">
                                                {localizer.msg('idea.actions.idea-number')} #{ideaNumber}
                                            </span>
                                            {
                                                ideaViewCount &&
                                                <span className="idea-views">
                                                    {localizer.msg('idea.actions.idea-views')} {ideaViewCount}
                                                </span>
                                            }

                                        </div>
                                    </header>
                                    <StageAction ideaStageSummary={stageSummary} onClickAction={onClickStageAction}
                                                 onVote={onVote}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </Fragment>

    );
};