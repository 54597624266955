import React, {useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from '@ideascale/ui';
import {
    disableScrolling,
    enableScrolling,
    LinkIdeaQualifier,
    LinkQualifier,
    Localizer,
    useToggle
} from '@ideascale/commons';
import {LinkableIdea} from 'models/LinkableIdea';

type AddLinkActionProps = {
    localizer: Localizer;
    onClickQualifier: (idea: LinkableIdea, qualifier: LinkIdeaQualifier) => void;
    linkQualifiers: LinkQualifier;
    linkableIdea: LinkableIdea;
    containerElementId: string;
    toggleButtonClassName?: string;
    caret?: boolean;
}


export const AddLinkAction = (props: AddLinkActionProps) => {
    const {localizer, linkQualifiers, containerElementId, onClickQualifier, linkableIdea} = props;
    const [open, toggle] = useToggle(false);
    const [toggleButtonText, setToggleButtonText] = useState<string>(localizer.msg('ideas.terms.add-link'));
    const menuFocusId = `linkable-ideas-menu-${linkableIdea.id}`;

    if (open) {
        disableScrolling(containerElementId);
    } else {
        enableScrolling(containerElementId);
    }

    const buildLinkIdeaQualifier = (qualifier: [string, string]) => {
        const id = +qualifier[0];
        const name = qualifier[1];
        return new LinkIdeaQualifier(id, name);
    };

    return (
        <Dropdown isOpen={open} toggle={toggle}>
            <DropdownToggle className="btn-light fw-normal btn-link-action text-start" caret
                            data-test-element-id="add-linked-idea">
                <span className="text-truncate d-inline-block pointer-events-none">{toggleButtonText}</span>
            </DropdownToggle>
            <DropdownMenu className="modal-dropdown-z-index" id={menuFocusId} drawerAble={false}
                          data-test-element-id="link-types" end>
                {Object.entries(linkQualifiers).map((qualifier) =>
                    <DropdownItem key={qualifier[0]} active={toggleButtonText === qualifier[1]}
                                  onClick={() => {
                                      setToggleButtonText(qualifier[1]);
                                      onClickQualifier(linkableIdea, buildLinkIdeaQualifier(qualifier));
                                  }}>{qualifier[1]}</DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};
